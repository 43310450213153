<template>
  <v-card>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn @click="updateOrdem()" color="save" class="white--text mr-4">
        <v-icon left> mdi-order-numeric-ascending</v-icon>
        Confirmar Ordenação
      </v-btn>
    </div>
    <v-row class="pa-2 d-flex justify-center align-center">
      <draggable
        class=""
        :list="palestras"
        :component-data="getComponentData()"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <v-col
            style="cursor: pointer"
            class="py-0 pl-4 my-5 d-flex justify-center align-center"
            v-for="(palestra, i) in palestras"
            :key="i + 0"
            cols="12"
            md="12"
          >
            <v-hover v-slot="{ hover }">
              <div class="d-flex justify-center align-center">
                <div>
                  <v-icon
                    class="drag-icon"
                    right
                    :class="{ 'on-hover': hover }"
                    size="60"
                    >mdi-drag</v-icon
                  >
                </div>
                <div>
                  <v-card width="900px" class="">
                    <v-row no-gutters>
                      <v-col cols="12" md="4">
                        <v-card
                          tile
                          flat
                          height="220px"
                          max-height="220px"
                          max-width="280px"
                          class="accent fill-height justify-center align-center d-flex"
                        >
                          <v-img
                            cover
                            height="220px"
                            max-height="220px"
                            max-width="280px"
                            v-if="palestra.img_destaque"
                            :src="palestra.img_destaque"
                          >
                          </v-img>
                          <v-icon v-else size="60" color="info">
                            mdi-image-multiple-outline
                          </v-icon>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="8"
                        class="px-3 py-1 d-flex justify-space-between"
                        style="flex-direction: column"
                      >
                        <div class="d-flex align-center justify-center">
                          <span
                            class="text-subtitle-1 font-weight-bold text-h5 to-uppercase py-2"
                          >
                            {{ palestra.descricao | excerpt(35) }}
                          </span>
                          <v-spacer></v-spacer>
                          <div class="d-block">
                            <div class="d-flex align-center justify-center">
                              <span class="text-h5 text-center pr-2">
                                {{ palestra.data | dateFormat("dd") }}
                              </span>
                              <span
                                class="text-subtitle-1 text-center justify-center"
                              >
                                {{ formatData(palestra.data, "MMM") }}
                              </span>
                            </div>
                            <div class="d-block">
                              <span class="text-caption">
                                Início às
                                {{ palestra.data | dateFormat("HH:mm") }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <span class="text-body-2">
                          {{ palestra.descricao_detalhada | excerpt(115) }}
                        </span>
                        <div class="d-flex align-center my-4">
                          <span class="text-subtitle-2 mr-2">Palestrante:</span>
                          <div>
                            <v-img
                              cover
                              class="palestrante-avatar"
                              v-if="palestra.palestrante_avatar"
                              :src="palestra.palestrante_avatar"
                            ></v-img>
                          </div>
                          <div class="ml-2 d-block">
                            <div>
                              <span class="text-subtitle-2">
                                {{ palestra.palestrante | excerpt(25) }}
                              </span>
                            </div>

                            <div v-if="palestra.palestrante_ramoatividade">
                              <span class="text-caption">
                                {{
                                  palestra.palestrante_ramoatividade
                                    | excerpt(25)
                                }}
                              </span>
                            </div>
                          </div>
                          <v-spacer></v-spacer>
                          <v-btn text small>
                            <v-icon left>mdi-plus</v-icon>
                            leia mais
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </div>
            </v-hover>
          </v-col>
        </transition-group>
      </draggable>
    </v-row>
    <!-- {{ palestras }} -->
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import { format, parseISO } from "date-fns";
import dateLocalePortugues from "date-fns/locale/pt-BR";
import {
  all as fetchPalestras,
  ordenarPalestras,
} from "@/api/admin/palestras.js";
export default {
  props: {
    diaPalestra: {
      type: String,
    },
    ordenar: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    draggable,
  },

  data() {
    return {
      loading: true,
      palestras: [],
      palestrasOrdenadas: [],
      drag: false,
      loadingOrdenacao: false,
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    handleChange() {
      this.palestrasOrdenadas = this.palestras;
    },
    inputChanged(value) {
      this.activeNames = value;
    },
    getComponentData() {
      return {
        on: {
          change: this.handleChange,
          input: this.inputChanged,
        },
        attrs: {
          wrap: true,
        },
        props: {
          value: this.activeNames,
        },
      };
    },
    goToPalestra(item) {
      this.$router.push({ path: `/palestras/${item.id}` });
    },

    updateOrdem() {
      if (this.palestrasOrdenadas.length) {
        this.loadingOrdenacao = true;

        let palestra = {};

        for (palestra in this.palestrasOrdenadas) {
          this.palestrasOrdenadas[palestra].ordem = palestra;
        }
        let palestrasOrdem = this.palestrasOrdenadas.map((item) => ({
          id: item.id,
          ordem: parseInt(item.ordem),
        }));

        let body = {};
        body.palestras = palestrasOrdem;

        ordenarPalestras(body)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Ordem alterada com sucesso");
              this.$emit("update:ordenar", false);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$toast.error("Nenhuma alteração foi feita");
        this.$emit("update:ordenar", false);
      }
    },

    getPalestras() {
      this.loading = true;
      fetchPalestras("?&dia=" + this.diaPalestra)
        .then((response) => {
          this.palestras = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatData(date, formato) {
      let options = {
        locale: dateLocalePortugues,
      };
      return format(parseISO(date), formato, options);
    },
  },

  mounted() {
    this.getPalestras();
  },
};
</script>

<style lang="scss" scoped>
.to-uppercase {
  text-transform: uppercase;
}
.palestrante-avatar {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 100%;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.drag-icon {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s opacity 2s linear;
}
.drag-icon.on-hover {
  visibility: visible;
  opacity: 1;
  transition: visibility 1s opacity 2s linear;
}
</style>
